@font-face {
  font-family: 'fa-regular';
  src:
    url('./font/fa-regular-400.woff2') format('woff2'),
    url('./font/fa-regular-400.woff') format('woff'),
    url('./font/fa-regular-400.ttf') format('truetype');
}
@font-face {
  font-family: 'fa-brands';
  src:
    url('./font/fa-brands-400.woff2') format('woff2'),
    url('./font/fa-brands-400.woff') format('woff'),
    url('./font/fa-brands-400.ttf') format('truetype');
}
@font-face {
  font-family: 'fa-solid';
  src:
    url('./font/fa-solid-900.woff2') format('woff2'),
    url('./font/fa-solid-900.woff') format('woff'),
    url('./font/fa-solid-900.ttf') format('truetype');
}
@font-face {
  font-family: 'icomoon';
  src:
    url('./font/icomoon.woff') format('woff'),
    url('./font/icomoon.ttf') format('truetype');
}
@font-face {
  font-family: 'themify';
  src:
    url('./font/themify.woff') format('woff'),
    url('./font/themify.ttf') format('truetype');
}

// 폰트패밀리 변수
$faRegular: 'fa-regular', sans-serif;
$faBrands: 'fa-brands', sans-serif;
$faSolid: 'fa-solid', sans-serif;
$icoMoon: 'icomoon', sans-serif;
$theMify: 'themify', sans-serif;

// 폰트 변수
$base-font-size: 10px;
$main-textColor: #444;
$sub-textColor: #eee;

:root {
  //색상정의
  --mainColor: #333;
  --whiteColor: #fff;
  --darkColor: #000;
  --pointColor: #2349a7;
  --actColor: #a72323;

  //폰트정리
  --fontSize: 1rem;
  --fontWeightNormal: 400;
  --fontWeightBold: 700;

  //여백
  --space10: 10px;
  --space20: 20px;
  --space30: 30px;
  --space40: 40px;
  --space50: 50px;
  --space60: 60px;
  --space70: 70px;
  --space80: 80px;
  --space90: 90px;
  --space100: 100px;
}

.thisMargin {
  display: block;
  width: 50%;
  margin: 0 auto;
}
